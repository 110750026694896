// extracted by mini-css-extract-plugin
export var active = "client-member-listing-module--active--a0f11";
export var backgroundAnimation = "client-member-listing-module--backgroundAnimation--9c61e";
export var container = "client-member-listing-module--container--d4ed7";
export var createBox = "client-member-listing-module--create-box--73863";
export var grid = "client-member-listing-module--grid--50c53";
export var inactive = "client-member-listing-module--inactive--93df9";
export var loader = "client-member-listing-module--loader--e0195";
export var loading = "client-member-listing-module--loading--e125f";
export var sectionBox = "client-member-listing-module--section-box--1a20f";
export var tableGrid = "client-member-listing-module--table-grid--38e3e";