import { useMemo } from 'react';

import { getRtkQueryErrors } from '../utils/get-rtk-query-errors';
import useTranslations from './use-translations';
import {
    useGetClientMemberQuery,
    useCreateClientMemberMutation,
    useDeleteClientMemberMutation,
    useUpdateClientMemberMutation,
    useGetClientMemberStatusesQuery,
    useGetClientMemberRolesQuery,
    useGetAllClientMemberQuery,
} from '../redux/api/client-member';

export type TUseClientMemberQueries = 'single' | 'statuses' | 'roles' | 'all';

interface IUseClientMemberConfig {
    queries?: TUseClientMemberQueries[];
    memberId?: string | number | null;
}

export const useClientMember = ({
    queries = [],
    memberId,
}: IUseClientMemberConfig | undefined = {}) => {
    const t = useTranslations('RTKQueryApi');

    const skipMemberQuery = !queries.includes('single') || !memberId;
    const skipStatusesQuery = !queries.includes('statuses');
    const skipRolesQuery = !queries.includes('roles');
    const skipAllQuery = !queries.includes('all');

    const getMemberQuery = useGetClientMemberQuery(memberId, { skip: skipMemberQuery });
    const getStatusesQuery = useGetClientMemberStatusesQuery(undefined, {
        skip: skipStatusesQuery,
    });
    const getRolesQuery = useGetClientMemberRolesQuery(undefined, { skip: skipRolesQuery });
    const getAllQuery = useGetAllClientMemberQuery(undefined, { skip: skipAllQuery });
    const [create, createMutation] = useCreateClientMemberMutation();
    const [update, updateMutation] = useUpdateClientMemberMutation();
    const [deleteTemplate, deleteMutation] = useDeleteClientMemberMutation();

    const singleErrors = useMemo(() => {
        return getRtkQueryErrors(getMemberQuery.error, t.errors);
    }, [getMemberQuery.error, t.errors]);
    const statusesErrors = useMemo(() => {
        return getRtkQueryErrors(getStatusesQuery.error, t.errors);
    }, [getStatusesQuery.error, t.errors]);
    const rolesErrors = useMemo(() => {
        return getRtkQueryErrors(getRolesQuery.error, t.errors);
    }, [getRolesQuery.error, t.errors]);
    const allErrors = useMemo(() => {
        return getRtkQueryErrors(getAllQuery.error, t.errors);
    }, [getAllQuery.error, t.errors]);
    const createErrors = useMemo(() => {
        return getRtkQueryErrors(createMutation.error, t.errors);
    }, [createMutation.error, t.errors]);
    const updateErrors = useMemo(() => {
        return getRtkQueryErrors(updateMutation.error, t.errors);
    }, [updateMutation.error, t.errors]);
    const deleteErrors = useMemo(() => {
        return getRtkQueryErrors(deleteMutation.error, t.errors);
    }, [deleteMutation.error, t.errors]);

    return {
        single: {
            data: getMemberQuery.data,
            isFetching: getMemberQuery.isFetching,
            isLoading: getMemberQuery.isLoading,
            isSuccess: getMemberQuery.isSuccess,
            isError: getMemberQuery.isError,
            errors: singleErrors,
        },
        all: {
            data: getAllQuery.data,
            isFetching: getAllQuery.isFetching,
            isLoading: getAllQuery.isLoading,
            isSuccess: getAllQuery.isSuccess,
            isError: getAllQuery.isError,
            errors: allErrors,
        },
        statuses: {
            data: getStatusesQuery.data,
            isFetching: getStatusesQuery.isFetching,
            isLoading: getStatusesQuery.isLoading,
            isSuccess: getStatusesQuery.isSuccess,
            isError: getStatusesQuery.isError,
            errors: statusesErrors,
        },
        roles: {
            data: getRolesQuery.data,
            isFetching: getRolesQuery.isFetching,
            isLoading: getRolesQuery.isLoading,
            isSuccess: getRolesQuery.isSuccess,
            isError: getRolesQuery.isError,
            errors: rolesErrors,
        },
        create: {
            fetch: create,
            data: createMutation.data,
            isLoading: createMutation.isLoading,
            isSuccess: createMutation.isSuccess,
            isError: createMutation.isError,
            errors: createErrors,
        },
        update: {
            fetch: update,
            data: updateMutation.data,
            isLoading: updateMutation.isLoading,
            isSuccess: updateMutation.isSuccess,
            isError: updateMutation.isError,
            errors: updateErrors,
        },
        delete: {
            fetch: deleteTemplate,
            data: deleteMutation.data,
            isLoading: deleteMutation.isLoading,
            isSuccess: deleteMutation.isSuccess,
            isError: deleteMutation.isError,
            errors: deleteErrors,
        },
    };
};
