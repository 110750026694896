import alterpressApi from './base';

import { IClientMember } from '../../models/client-member.model';
import { IClientMemberValues } from '../../formik/client-member.form';
import { IOption } from '../../models/option.model';

export const clientMemberApi = alterpressApi
    .enhanceEndpoints({
        addTagTypes: ['CLIENT_MEMBER', 'CLIENT_TEAM', 'CLIENT_PROJECT'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getClientMember: builder.query<IClientMember, number | string | undefined | null>({
                query: (memberId) => `client-members/${memberId}?expand=teams`,
                providesTags: ['CLIENT_MEMBER', 'CLIENT_TEAM', 'CLIENT_PROJECT'],
            }),
            getAllClientMember: builder.query<IClientMember[], void>({
                query: () => `client-members/all`,
                providesTags: ['CLIENT_MEMBER', 'CLIENT_TEAM', 'CLIENT_PROJECT'],
            }),
            getClientMemberStatuses: builder.query<IOption[], void>({
                query: () => `client-members/statuses`,
            }),
            getClientMemberRoles: builder.query<IOption[], void>({
                query: () => `client-members/roles`,
            }),
            createClientMember: builder.mutation<IClientMember, IClientMemberValues>({
                query: (data) => ({
                    url: `client-members`,
                    method: 'POST',
                    body: data,
                }),
                invalidatesTags: ['CLIENT_MEMBER'],
            }),
            updateClientMember: builder.mutation<
                IClientMember,
                { memberId: number | string; data: IClientMemberValues }
            >({
                query: ({ data, memberId }) => ({
                    url: `client-members/${memberId}`,
                    method: 'PATCH',
                    body: data,
                }),
                invalidatesTags: ['CLIENT_MEMBER'],
            }),
            deleteClientMember: builder.mutation<void, number | string | null | undefined>({
                query: (memberId) => ({
                    url: `client-members/${memberId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['CLIENT_MEMBER'],
            }),
        }),
    });

export const {
    useGetAllClientMemberQuery,
    useGetClientMemberStatusesQuery,
    useGetClientMemberRolesQuery,
    useGetClientMemberQuery,
    useCreateClientMemberMutation,
    useUpdateClientMemberMutation,
    useDeleteClientMemberMutation,
} = clientMemberApi;
