import React from 'react';
import { Formik } from 'formik';
import { graphql, useStaticQuery } from 'gatsby';

import {
    sectionBox,
    container,
    tableGrid,
    createBox,
    loader,
    grid,
    active,
    inactive,
    loading,
} from './client-member-listing.module.scss';
import { ISection } from '../../models/section.model';
import { TLocale } from '../../locale';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { IPage } from '../../models/page.model';
import { IClientMember } from '../../models/client-member.model';
import { IClient } from '../../models/client.model';
import useTranslations from '../../hooks/use-translations';
import { useList } from '../../hooks/use-list';
import { useClient } from '../../hooks/use-client';
import { getDateFromUnixTimestamp } from '../../utils/get-date-from-unix-timestamp';
import { useModal } from '../../hooks/use-modal';
import { useClientMember } from '../../hooks/use-client-member';
import { usePagePathname } from '../../hooks/use-page-pathname';
import { useClientPermission } from '../../hooks/use-client-permission';

import Section from '../hoc/section';
import Loader from '../atoms/loader';
import Table, { ITableProps } from '../organisms/table';
import Button from '../atoms/button';
import Tooltip from '../atoms/tooltip';
import IconFactory from '../hoc/icon-factory';
import HandleFormikChange from '../hoc/handle-formik-change';
import AlertInfo from '../molecules/alert-info';
import DashboardHeader from '../molecules/dashboard-header';
import NoPermissionInfo from '../organisms/no-permission-info';

interface IClientMemberListingProps {
    section: ISection;
    TitleTag?: React.ElementType;
}

interface IClientMemberListingQueryResult {
    allPage: IQueryAllResult<Pick<IPage, 'pathname' | 'locale' | 'type'>>;
}

const ClientMemberListing: React.FC<IClientMemberListingProps> = ({ section, TitleTag = 'h2' }) => {
    const { style, css, sectionId } = section;
    const t = useTranslations('ClientMemberListing');
    const client = useClient();
    const { allPage } = useStaticQuery<IClientMemberListingQueryResult>(query);
    const { addModal } = useModal();
    const member = useClientMember();
    const memberPreviewPage = usePagePathname(allPage, 'client-member-preview');
    const memberCreatePage = usePagePathname(allPage, 'client-member-form');
    const clientFaqPage = usePagePathname(allPage, 'client-faq');
    const canUsers = useClientPermission(['can-users']);

    const {
        status,
        isInitialLoading,
        items,
        paginationPaths,
        handleChange,
        values,
        pagination,
        isEmpty,
        refetchList,
        sort,
    } = useList<IClientMember>({
        endpoint: `/client-members?expand=teams`,
        token: client.data?.token.token,
        perPage: 6,
    });

    const handleDeleteMember = (memberId: number) => {
        addModal({
            modalKey: 'delete-confirm-modal',
            modalProps: { onConfirm: handleDeleteMemberConfirmation(memberId) },
        });
    };

    const handleDeleteMemberConfirmation = (memberId: number) => {
        return async () => {
            try {
                await member.delete.fetch(memberId).unwrap();
                refetchList();
            } catch {
                addModal({ modalKey: 'delete-error-modal' });
            }
        };
    };

    if (!canUsers) {
        return <NoPermissionInfo isFullHeight={true} reason="role" />;
    }

    return (
        <Section
            className={sectionBox}
            classes={{ container: grid }}
            style={style}
            sectionId={sectionId}
            css={css}
        >
            {isInitialLoading && <Loader className={loader} />}
            {!isInitialLoading && (
                <Formik onSubmit={() => {}} initialValues={values || {}} enableReinitialize={true}>
                    {(formik) => (
                        <div className={`${container} ${member.delete.isLoading ? loading : ''}`}>
                            <HandleFormikChange onChange={handleChange} />
                            <DashboardHeader TitleTag={TitleTag} hint={t.hint}>
                                {t.title}
                            </DashboardHeader>
                            <div className={createBox}>
                                <Button as="link" to={memberCreatePage}>
                                    {t.new}
                                </Button>
                                <Tooltip>{t.newHint(clientFaqPage)}</Tooltip>
                            </div>
                            {isEmpty && <AlertInfo>{t.empty}</AlertInfo>}
                            {!isEmpty && (
                                <Table
                                    tableClassName={tableGrid}
                                    headerCells={getHeaderCells(t)}
                                    rows={getMemberRows(
                                        t,
                                        items,
                                        memberPreviewPage,
                                        memberCreatePage,
                                        handleDeleteMember,
                                        client.data
                                    )}
                                    status={status}
                                    totalCount={pagination?.totalCount || 0}
                                    paginationPaths={paginationPaths}
                                    formik={formik}
                                    sort={sort}
                                />
                            )}
                        </div>
                    )}
                </Formik>
            )}
        </Section>
    );
};

function getHeaderCells(t: TLocale['ClientMemberListing']): ITableProps['headerCells'] {
    return [
        { label: t.id },
        { label: t.name },
        { label: t.lastName },
        { label: t.phone },
        { label: t.email },
        { label: t.createdAt },
        { label: t.role.label, field: 'role' },
        { label: t.status.label },
        { label: t.team },
        { label: '' },
    ];
}

function getMemberRows(
    t: TLocale['ClientMemberListing'],
    members: IClientMember[],
    memberPreviewPage: string,
    memberCreatePage: string,
    onDeleteMember: (memberId: number) => void,
    client: IClient | null | undefined
): ITableProps['rows'] {
    return members.map((member) => {
        return [
            {
                type: 'data',
                label: t.id,
                value: member.memberId,
                valueStyle: 'bold',
            },
            {
                type: 'data',
                label: t.name,
                value: member.firstName,
            },
            {
                type: 'data',
                label: t.lastName,
                value: member.lastName,
            },
            {
                type: 'data',
                label: t.phone,
                value:
                    member.phonePrefix && member.phone
                        ? `${member.phonePrefix} ${member.phone}`
                        : '',
            },
            {
                type: 'data',
                label: t.email,
                value: member.email,
            },
            {
                type: 'data',
                label: t.createdAt,
                value: getDateFromUnixTimestamp(member.createdAt, 'dash'),
            },
            {
                type: 'data',
                label: t.role.label,
                value: getRoleTranslations(member.role, t),
            },
            {
                type: 'data',
                label: t.status.label,
                value: member.status === 0 ? t.status.inactive : t.status.active,
                valueClassName: member.status === 0 ? inactive : active,
            },
            {
                type: 'data',
                label: t.team,
                value:
                    member.teams.length > 0
                        ? member.teams.map((team) => team.name).join(', ')
                        : '-',
            },
            {
                type: 'action',
                actions: [
                    client?.memberId !== member.memberId
                        ? {
                              stylePreset: 'danger',
                              size: 'medium',
                              shape: 'circle',
                              children: <IconFactory icon="trash" />,
                              onClick: () => onDeleteMember(member.memberId),
                          }
                        : null,
                    {
                        as: 'link',
                        stylePreset: 'secondary',
                        size: 'medium',
                        shape: 'circle',
                        to: `${memberCreatePage}?id=${member.memberId}`,
                        children: <IconFactory icon="edit" />,
                    },
                    {
                        as: 'link',
                        stylePreset: 'secondary',
                        size: 'medium',
                        children: t.details,
                        to: `${memberPreviewPage}?id=${member.memberId}`,
                    },
                ],
            },
        ];
    });
}

const getRoleTranslations = (role: string, t: TLocale['ClientMemberListing']) => {
    switch (role) {
        case 'super-user':
            return t.role.superUser;
        case 'talent-acquisition':
            return t.role.talentAcquisition;
        case 'collaborator':
            return t.role.collaborator;
    }
};

export const query = graphql`
    query {
        allPage(
            filter: { type: { in: ["client-member-preview", "client-member-form", "client-faq"] } }
        ) {
            edges {
                node {
                    pathname
                    type
                    locale
                }
            }
        }
    }
`;

export default ClientMemberListing;
